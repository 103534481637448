import React from 'react';
import ExtractPage from './extract';
import './App.css';
import logo from './assets/0excel-white.jpg'; // 引入logo图片

const App = () => {
    return (
        <div style={{ padding: '20px' }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                <img src={logo} alt="0Excel Logo" style={{ width: '140px', height: 'auto', marginRight: '10px' }} /> {/* 显示logo */}
                <h1>：专门为 Excel 设计的 AI 档案库（资料库）和效率工具集————演示 1：数据清洗和治理</h1>
            </div>
            <ExtractPage />
        </div>
    );
};

export default App;