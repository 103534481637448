import React, { useState } from 'react';
import { Upload, Button, Input, Table, Tabs, message, Spin } from 'antd';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import axios from 'axios';
import * as XLSX from 'xlsx'; // 确保 XLSX 已安装

const { TabPane } = Tabs;

const BASE_URL = process.env.REACT_APP_API_BASE_URL || (window.env && window.env.REACT_APP_API_BASE_URL);

const ExtractPage = () => {
    const [file, setFile] = useState(null);
    const [fileUrl, setFileUrl] = useState(''); // 新增 fileUrl 状态
    const [input, setInput] = useState('');
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false); // 控制按钮状态
    const [excelHtml, setExcelHtml] = useState(null); // 用于保存Excel内容的HTML

    const handleUpload = (file) => {
        setFile(file);
        setFileUrl(''); // 清空 fileUrl

        const reader = new FileReader();
        reader.onload = (e) => {
            const binaryStr = e.target.result;
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
            const sheetName = workbook.SheetNames[0]; // 读取第一个工作表
            const worksheet = workbook.Sheets[sheetName];
            const html = XLSX.utils.sheet_to_html(worksheet); // 将 Excel 转为 HTML
            setExcelHtml(html); // 设置 Excel 的 HTML 内容
        };
        reader.readAsBinaryString(file); // 读取文件为二进制字符串
    };

    const handleInputChange = (e) => {
        setInput(e.target.value);
    };

    const handleFileUrlChange = (e) => {
        setFileUrl(e.target.value);
        setFile(null); // 清空 file 选择
    };

    const handleSubmit = async () => {
        if ((!file && !fileUrl) || !input) {
            message.error('请上传文件或输入文件 URL，并输入提取要求');
            return;
        }

        const formData = new FormData();
        if (file) {
            formData.append('file', file);
        } else {
            formData.append('file_url', fileUrl);
        }
        formData.append('user_input', input);
        setLoading(true);
        setIsSubmitting(true); // 禁用按钮，防止重复提交

        try {
            const response = await axios.post(`${BASE_URL}/extract_to_table/from_file`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data && response.data.data) {
                const responseData = response.data.data;
                setData(responseData);

                // 动态生成表格列
                const dynamicColumns = Object.keys(responseData[0]).map((key) => ({
                    title: key,
                    dataIndex: key,
                    key: key,
                }));
                setColumns(dynamicColumns);

                message.success('数据提取成功');
            } else {
                message.error('提取失败，请检查输入');
            }
        } catch (error) {
            message.error('请求失败，请稍后再试');
        } finally {
            setLoading(false);
            setIsSubmitting(false); // 允许再次提交
            setFile(null); // 清空文件
            setFileUrl(''); // 清空 URL
        }
    };

    const handleSaveExcel = () => {
        // 创建一个工作簿
        const workbook = XLSX.utils.book_new();
        // 将数据转换为工作表
        const worksheet = XLSX.utils.json_to_sheet(data);
        // 将工作表添加到工作簿
        XLSX.utils.book_append_sheet(workbook, worksheet, '0Excel提取的数据');
        // 导出工作簿
        XLSX.writeFile(workbook, '0Excel-提取并清洗后的数据.xlsx');
    };

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {!file && ( // 仅当未上传文件时显示上传按钮
                    <div>
                        <Upload
                            beforeUpload={(file) => {
                                handleUpload(file);
                                return false;
                            }}
                            accept=".xlsx"
                        >
                            <Button icon={<UploadOutlined />}>点击上传 Excel 文件</Button>
                        </Upload>
                    </div>
                )}
                {!file && <span style={{ margin: '0 10px' }}>或者</span>}
                {!file && ( // 仅当未上传文件时显示输入 URL
                    <Input
                        placeholder="请输入Excel 文件的下载地址（HTTP/HTTPS）"
                        value={fileUrl}
                        onChange={handleFileUrlChange}
                        style={{ width: '700px' }}
                    />
                )}
            </div>
            <div>
                {!file && <a
                    href="https://0excel-sample.oss-cn-shanghai.aliyuncs.com/0Excel%E7%A4%BA%E4%BE%8B%E6%96%87%E4%BB%B6001.xlsx"
                    style={{ marginLeft: '10px', fontSize: '12px', color: 'gray'}}
                >
                    下载示例文件
                </a>}
            </div>

            {file && <p>已选择文件: {file.name}</p>} {/* 显示已选文件名称 */}

            {excelHtml && (
                <div
                    dangerouslySetInnerHTML={{ __html: excelHtml }} // 渲染 Excel 内容为 HTML
                    style={{ marginTop: '20px', border: '1px solid #ccc', padding: '10px' }}
                />
            )}

            <Input
                placeholder="请输入提取要求，例如：提取单位、岗位和需求人数"
                value={input}
                onChange={handleInputChange}
                style={{ width: '450px', marginTop: '20px' }}
            />

            <Button
                type="primary"
                onClick={handleSubmit}
                disabled={(!file && !fileUrl) || !input || isSubmitting} // 禁用按钮，防止重复提交
                style={{ marginLeft: '10px' }}
            >
                {isSubmitting ? '处理中...' : '提取数据'} {/* 动态改变按钮文字 */}
            </Button>

            {loading ? (
                <Spin size="large" style={{ marginTop: '20px' }} />
            ) : (
                <Tabs defaultActiveKey="1" style={{ marginTop: '20px' }}>
                    <TabPane tab="表格展示" key="1">
                        <Table 
                            dataSource={data} 
                            columns={columns} 
                            rowKey={(record) => Object.values(record).join('_')} 
                            pagination={{ pageSize: 20 }} 
                            locale={{ emptyText: '没有数据' }} // 添加中文提示
                        />
                    </TabPane>
                    <TabPane tab="JSON展示" key="2">
                        <pre>{JSON.stringify(data, null, 2)}</pre>
                    </TabPane>
                    <TabPane tab="保存为Excel" key="3">
                        <Button 
                            type="" 
                            icon={<DownloadOutlined />}
                            onClick={handleSaveExcel}
                            style={{ marginTop: '20px' }}
                        >
                            保存为 Excel
                        </Button>
                    </TabPane>
                </Tabs>
            )}
        </div>
    );
};

export default ExtractPage;
